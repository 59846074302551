<!--文章管理-->
<template>
    <div>
        <a-row class="searchBox">
            <a-space class="flex flex-wrap">
                <a-input-search v-model:value="param.title" placeholder="请输入文章标题" style="width: 300px" @search="filterGetList" />
                <a-button type="primary" @click="handleEdit()">
                    <PlusCircleOutlined />添加文章
                </a-button>
            </a-space>
        </a-row>
        <a-row class="tableBox">
            <a-table :dataSource="list" :columns="columns" rowKey="id" :pagination="false" :scroll="{x: 1000, y: 'calc(100vh - 390px)' }">
                <template #number="{text, record, index}">
                    <span>{{ (param.curr - 1) * param.row + index + 1 }}</span>
                </template>
                <template #cover="{text, record, index}">
                    <a-image :width="60" :src="domain+text" :fallback="domain+'/default.png'" style="border-radius:4px" />
                </template>
                <template #operation="{ record }">
                    <a-space>
                        <a-button type="primary" @click="handleEdit(record)">编辑</a-button>
                        <a-button type="primary" @click="handleDelete(record)">删除</a-button>
                    </a-space>
                </template>
            </a-table>
            <div class="flex flex-jc-fe Pageing">
                <TablePagination :curr="param.curr" :row="param.row" :count="count" @changeTableRequest="changeTableRequest" />
            </div>
        </a-row>
    </div>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, getCurrentInstance, onMounted, createVNode } from 'vue';
import TablePagination from '@/components/TablePagination';
import useDelete from '@/hooks/useDelete.js';
import { PlusCircleOutlined } from '@ant-design/icons-vue';

export default defineComponent({
    components: {
        TablePagination,
        PlusCircleOutlined
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const { useDeleteFun } = useDelete();
        onMounted(() => {
            getList()
        });
        const initData = reactive({
            list: [],
            columns: [
                { title: '序号', dataIndex: 'number', width: 80, slots: { customRender: 'number' } },
                { title: '文章封面', dataIndex: 'cover', slots: { customRender: 'cover' } },
                { title: '文章标题', dataIndex: 'title' },
                { title: '文章标签', dataIndex: 'tag' },
                { title: '上传时间', dataIndex: 'create_time' },
                { title: '操作', fixed: 'right', width: 180, slots: { customRender: 'operation' } },
            ],
            param: {
                curr: 1,
                row: 10
            },
            count: 0,
            pages: 1,
            domain: proxy.domain
        });
        //文章列表
        const getList = () => {
            proxy.http.post('/admin.article/index', initData.param).then(response => {
                initData.list = response.list;
                initData.count = response.count;
                initData.pages = response.pages;
            })
        };

        // 筛选
        const filterGetList = (value) => {
            initData.list = [];
            initData.param.curr = 1;
            initData.param.title = value;
            getList();
        };

        const changeTableRequest = (event, value) => {
            if (event == 'curr') {
                initData.param.curr = value;
            } else {
                initData.param.curr = 1;
                initData.param.row = value;
            }
            getList();
        };

        //新建弹框
        const handleEdit = (row) => {
            proxy.http.go('/ArticleManagmentEdit', { id: row ? row.id : '' })
        };

        //删除
        const handleDelete = (row) => {
            useDeleteFun('/admin.article/delete', [row.id]).then(() => {
                getList();
            })
        }

        return {
            ...toRefs(initData),
            getList,
            filterGetList,
            changeTableRequest,
            handleEdit,
            handleDelete
        }
    }
})
</script>
